<template>


<button type="submit" v-bind:disabled="loading_flag" v-bind:class="['btn btn-gradient-primary',{'btn-block':wide}]">{{loading_flag?label_loading:label}} <b-spinner class="float-right" small v-if="loading_flag" label="Cargando..."></b-spinner></button>


</template>

<script>
export default {
    name: 'submit-button',
   props:{

        label:{
            type:String,
            required:true
        },
        loading_flag:{
            type:Boolean,
            required:true,
            default:false
        },
        label_loading:{
            type:String,
            required:false,
            default:'Cargando...'

        },
        wide:{
            type:Boolean,
            required:false,
            default:false
        }

      }
}

</script>